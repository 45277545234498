import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// const BLOG_URL = process.env.REACT_APP_STRAPI_BLOG_URL || 'http://localhost:1337/api';

export default function PostTemplete(props) {
	// const regex = /!\[.\w*\.(png|jp(e)?g|gif){1}\]\((\/uploads\/\w*\.(png|jp(e)?g|gif){1})\)/gm;
	const history = useHistory();
	const {
		title,
		sub,
		content,
		picture,
		publishDate,
		// publishTime,
		// reactions,
		author,
	} = props;
	let fixed_content = content;
	let reading_time = null;

	// add host to image paths
	if (content) {
		// const img_original_paths = Array.from(content.matchAll(regex)).map((found) => found[3]);
		// const img_new_paths = img_original_paths.map((path) => [path, `${BLOG_URL}${path}`]);
		// fixed_content = img_new_paths.reduce((prev, npath) => prev.replaceAll(npath[0], npath[1]), content);

		const words_number = content.split(' ').length;
		reading_time = Math.ceil(words_number / 200) + 1;
	}

	const picAlt = title?.toLowerCase();

	// Nice to have: grow number of reactions onMouseDown
	// function skyRocketReaction(reactionType) {
	// }

	const components = {
		// this custom renderer changes how images are rendered
		// used it to constrain the max width of an image to its container

		img: (props) => <img {...props} className='img-fluid' alt='' />,
		strong: (props) => (
			<Typography variant='fatHeader' {...props} />
			// <strong {...props} />
		),
		a: (props) => <Link {...props}>{props.children}</Link>,
	};

	return (
		<Box>
			<Container>
				<Button
					onClick={() => history.push('/blog')}
					variant='green'
					to='/blog'
					startIcon={<ArrowBackIcon />}
				>
					Back to all posts
				</Button>
			</Container>

			<Container sx={{ paddingTop: 3, overflow: 'hidden', width: '100%', height: '250px' }}>
				<img src={picture} alt={picAlt} width='100%' />
			</Container>

			<Container sx={{ paddingTop: 6 }}>
				<Grid container>
					<Grid item xs={12} md={4} paddingRight={4}>
						<Box paddingBottom={3}>
							<Typography variant='blogPostTitle'>{title}</Typography>
						</Box>
						<Box paddingBottom={3}>
							<Typography variant='blogPostSubTitle'>{sub}</Typography>
						</Box>
						<Box paddingBottom={2}>
							<Typography variant='h6'>By {author}</Typography>
						</Box>
						<Box paddingBottom={2}>
							<Typography variant='h6'>Published on {publishDate}</Typography>
						</Box>
						<Box paddingBottom={2}>
							<Typography variant='h6'>{reading_time} minutes read</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} md={8}>
						<ReactMarkdown components={components}>{fixed_content || content}</ReactMarkdown>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
