import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Badge, Box, Container, Grid, Typography } from '@mui/material';
import ProductCard from '../components/product_card';
import { products } from '../misc/text/products';

export function ProductsPage() {
	const [state, setState] = React.useState(0);
	const handleProductCardOnClick = (productId) => {
		setState(productId);
	};
	return (
		<Box>
			<Helmet key='product'>
				<meta
					charSet='utf-8'
					name='description'
					content='Oxari Products include Temperature Scoring, Custom Climate, Economic and ESG Dashboards, Corporate Carbon Estimation, Carbon and Benchmarking Report.'
				/>
				<title>Oxari Products</title>
			</Helmet>
			<Container>
				<Box
					paddingTop={6}
					paddingBottom={3}
					sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
				>
					<Typography variant='pageHeader'>Carbon intelligence solutions</Typography>
				</Box>

				<Box
					my={6}
					sx={{
						width: { md: '70%' },
					}}
				>
					<Typography
						variant='h4'
						sx={{
							textAlign: {
								xs: 'center',
								sm: 'left',
								md: 'left',
							},
						}}
					>
						Everything an investor needs to gain transparency into corporate emissions data,
						identify long-term adaptable stocks, track market trends, and navigate regulatory risks
						effectively.{' '}
					</Typography>
				</Box>

				<Grid container mb={6}>
					{products.map((product, i) => (
						<Grid item xs={6} sm={6} md={3} p={1} key={`productCard${i}`}>
							<Badge
								color='primary'
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								// invisible={product.id !== '3'}
								// badgeContent={'coming soon'}
								sx={{
									width: '100%',
									height: '100%',
									'& > span': {
										transform: 'scale(1) translate(15%, -50%)',
									},
								}}
							>
								<Box
									onClick={() => handleProductCardOnClick(product.id)}
									sx={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										flex: '1 1 50%',
										borderRadius: '1.25rem',
										backgroundColor: parseInt(state) === i ? '#A1F8B4' : '#D9D9D9',
										minHeight: '180px',
										p: 2,
										color: 'black',
										textTransform: 'none',
										'&:hover': { backgroundColor: '#A1F8B4' },
									}}
								>
									<product.icon sx={{ fontSize: '3rem', margin: 1 }} />
									<Typography
										variant='h6'
										textAlign={'center'}
										maxWidth='300px'
										fontWeight={'600'}
										m='auto'
									>
										{product.title}
									</Typography>
								</Box>
							</Badge>
						</Grid>
					))}
				</Grid>
			</Container>

			<ProductCard
				id={products[state].id}
				title={products[state].title}
				list={products[state].list}
				paragraphs={products[state].paragraphs}
				extra={products[state].extra}
			/>
		</Box>
	);
}
