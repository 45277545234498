import React from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import {
	Container,
	Grid,
	Typography,
	Box,
	List,
	ListItem,
	Button,
	Modal,
	CardActionArea,
	Card,
	CardMedia,
} from '@mui/material';

// import CCCMethods from '../components/products_CCE_card';
import OXARI_OTS_METHODOLOGY from '../docs/OXARI_OTS_METHODOLOGY.pdf';
import OXARI_EEM_EXPLAINER from '../docs/OXARI_EEM_EXPLAINER.pdf';
import { useHistory } from 'react-router-dom';
// import ProductContactForm from "./newsletter_signup"
import ContactForm from './contact_form';

const COLTURA_DASHBOARD_LINK = 'https://data.coltura.org/tools/map';

export default function ProductCard(props) {
	const {
		title,
		list,
		paragraphs,
		id,
		//extra
	} = props;
	const history = useHistory();
	const [isModal, setIsModal] = React.useState(false);

	const handleClickEvent = () => {
		setIsModal((prev) => !prev);
	};

	return (
		<Box bgcolor={'white'} py={10}>
			<Helmet key='product-card'>
				<meta charSet='utf-8' name='description' content={paragraphs[0]} />
				<title>{title}</title>
			</Helmet>
			<Container>
				<Grid container paddingBottom={6}>
					<Grid item xs={12} md={12}>
						<Box
							paddingTop={3}
							paddingBottom={3}
							sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left' } }}
						>
							<Typography variant='fatHeader'>{title}</Typography>
						</Box>
					</Grid>

					<Grid item xs={12} md={8} paddingBottom={4}>
						{paragraphs.map((paragraph, i) => (
							<Box paddingBottom={2} key={`productPara${i}`}>
								<Typography
									variant='body1'
									sx={{
										marginRight: {
											xs: 0,
											sm: 8,
											md: 8,
										},
									}}
									align='justify'
								>
									{paragraph}
								</Typography>
							</Box>
						))}
						{id === '0' && (
							<Grid container>
								<Button
									onClick={() => {
										ReactGA.event({
											category: 'button_click',
											action: 'see_oxari_model_explainer',
											label: 'products_button',
										});
										window.open(OXARI_EEM_EXPLAINER, '_blank');
									}}
									variant='green'
								>
									{' '}
									See Model Explainer{' '}
								</Button>
								<Grid
									item
									xs={12}
									md={12}
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										marginRight: {
											xs: 0,
											sm: 8,
											md: 8,
										},
									}}
								>
									<Card
										sx={{
											width: 560,
											height: 315,
											margin: 5,
										}}
									>
										<CardActionArea>
											<CardMedia
												component='iframe'
												width='560'
												height='315'
												src='https://www.youtube.com/embed/dNZhBhjAAqg'
												title='YouTube video player'
												frameBorder='0'
												allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
												allowFullScreen
											/>
										</CardActionArea>
									</Card>
								</Grid>
							</Grid>
						)}
						{id === '1' ? (
							<Button
								onClick={() => {
									ReactGA.event({
										category: 'button_click',
										action: 'consult_oxari_methodology',
										label: 'products_button',
									});
									window.open(OXARI_OTS_METHODOLOGY, '_blank');
								}}
								variant='green'
							>
								{' '}
								Consult Oxari Methodology{' '}
							</Button>
						) : (
							<div></div>
						)}
						{id === '2' ? (
							<Button
								onClick={() => {
									ReactGA.event({
										category: 'button_click',
										action: 'view_coltura_dashboard',
										label: 'products_button',
									});
									window.open(COLTURA_DASHBOARD_LINK, '_blank');
								}}
								variant='green'
							>
								{' '}
								Visit Coltura Dashboard{' '}
							</Button>
						) : (
							<div></div>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						sx={{
							backgroundColor: '#39393A',
							borderRadius: '20px',
							paddingLeft: 5,
							paddingTop: 3,
							paddingBottom: 3,
							color: 'white',
						}}
					>
						<Typography variant='subtitle' p={1} lineHeight='60px'>
							Do you need to...
						</Typography>
						<List
							sx={{
								listStyleType: 'disc',
								paddingRight: 3,
							}}
						>
							{list.map((item, i) => (
								<ListItem sx={{ display: 'list-item' }} key={`productList${i}`}>
									{item}
								</ListItem>
							))}
						</List>
					</Grid>
				</Grid>

				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={{ xs: 'center', md: 'flex-end' }}
					m={{ xs: 0, md: 4 }}
					gap={2}
				>
					<Box>
						<Typography variant='h6' display='inline-block' mr={2}>
							Are you interested?
						</Typography>

						<Button
							onClick={() => {
								ReactGA.event({
									category: 'button_click',
									action: 'sign_up',
									label: 'products_button',
								});
								history.push('/pricing');
							}}
							variant='green'
							// to="/pricing"
						>
							See pricing
						</Button>
					</Box>
					<Box>
						<Typography variant='h6' display='inline-block' mr={2}>
							Do you have questions?
						</Typography>
						<Button
							onClick={
								// ReactGA.event({'category': 'button_click', 'action': 'contact_us', 'label': 'products_button' })
								handleClickEvent
							}
							variant='green'
						>
							Contact us
						</Button>
					</Box>
				</Box>

				<Modal open={isModal} onClose={handleClickEvent}>
					<>
						<ContactForm />
					</>
				</Modal>

				{/* TODO To be implemented */}
				{/* {title === 'Corporate Carbon Estimation' && <CCCMethods />} */}
			</Container>
		</Box>
	);
}
