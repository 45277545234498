import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { NavLink as CustomNavLink } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@mui/material/styles';

import {
	AppBar,
	Box,
	Divider,
	Toolbar,
	IconButton,
	Typography,
	Menu,
	MenuItem,
	Container,
	Button,
	Link,
	Stack,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '../imgs/oxari.webp';
import { LUCRUM_URL, BACKEND_URL } from './constants';

const pages = ['Products', 'Data', 'About', 'Blog', 'Docs', 'Pricing'];

const API_URL = BACKEND_URL + '/redoc';
const STOREFRONT_URL = 'https://data.oxari.io/';

function PageLink(props) {
	const { page, path, handleCloseNavMenu } = props;

	return (
		<>
			<Link
				key={page}
				{...(page === 'Docs'
					? { href: API_URL, target: '_blank', rel: 'noopener noreferrer' }
					: page == 'Data'
					? { href: STOREFRONT_URL, target: '_blank', rel: 'noopener noreferrer' }
					: { to: path, component: CustomNavLink })}
				onClick={() => {
					handleCloseNavMenu();
				}}
				noWrap
				variant='navbar'
				underline='none'
				// {...(page === 'API' && {href :${LUCRUM_URL} + '/signin'})}
				// {...(page=== 'API' &&  { 'to' : path })}
				sx={{
					display: { xs: 'none', md: 'flex' },
					color: '#000000',
					textDecoration: 'none',
					align: 'center',
					'&:hover': {
						fontWeight: 600,
						color: '#000000',
						textDecoration: 'none',
					},
					'&:active': {
						fontWeight: 600,
						textUnderlineOffset: 3,
						color: '#000000',
						textDecoration: 'none',
					},
				}}
			>
				{page}
			</Link>
		</>
	);
}

export default function ResponsiveAppBar() {
	const theme = useTheme();
	const history = useHistory();
	const [anchorElNav, setAnchorElNav] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<ThemeProvider theme={theme}>
			<AppBar
				position='static'
				sx={{ bgcolor: 'background.default', paddingTop: 5, paddingBottom: 5 }}
			>
				<Container maxWidth='xl'>
					<Toolbar>
						<Box
							component='img'
							sx={{
								display: { xs: 'none', md: 'flex' },
								mr: 2,
								ml: 1,
								height: 90,
								width: 'auto',
								cursor: 'pointer',
							}}
							alt='oxari-logo'
							src={logo}
							onClick={() => {
								history.push('/');
							}}
						/>

						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleOpenNavMenu}
								color='inherit'
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								{pages.map((page, idx) => (
									<MenuItem
										key={idx}
										{...(page === 'API'
											? {
													href: API_URL,
													target: '_blank',
													rel: 'noopener noreferrer',
													component: Link,
											  }
											: { to: page.toLowerCase(), component: CustomNavLink })}
										onClick={() => {
											handleCloseNavMenu();
										}}
									>
										<Typography textAlign='center'>{page}</Typography>
									</MenuItem>
								))}
								<Divider />
								<MenuItem
									component={Link}
									href={LUCRUM_URL + '/signin'}
									rel='noopener noreferrer'
									target='_blank'
								>
									Sign In
								</MenuItem>
							</Menu>
						</Box>
						<Box
							component='img'
							sx={{
								display: { xs: 'flex', md: 'none' },
								mr: 1,
								height: 60,
								width: 'auto',
							}}
							alt='logo'
							src={logo}
						/>

						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							flex={1}
							display={{ xs: 'none', md: 'flex' }}
						>
							<Stack
								direction='row'
								justifyContent={'space-around'}
								flexGrow={1}
								maxWidth={'700px'}
							>
								{pages.map((page, idx) => {
									const path = page.toLowerCase();
									return (
										<PageLink
											key={idx}
											page={page}
											path={path}
											handleCloseNavMenu={handleCloseNavMenu}
										/>
									);
								})}
							</Stack>
							<Button
								variant='contained'
								disableElevation={true}
								component={'a'}
								href={LUCRUM_URL + '/signin'}
								sx={{
									ml: 4,
									borderRadius: 1.5,
									minWidth: '150px',
									fontSize: '20px',
									fontWeight: 'h4.fontWeight',
									lineHeight: '24.38px',
									color: '#FFFFFF',
									bgcolor: theme.palette.secondary.dark,
									textTransform: 'none',
									'&:hover': {
										backgroundColor: '#ffffff',
										color: '#000000',
										border: 2,
										borderColor: '#000000',
									},
								}}
							>
								Sign In
							</Button>
						</Stack>
					</Toolbar>
				</Container>
			</AppBar>
		</ThemeProvider>
	);
}
