import React, { useState } from 'react';
import Iframe from 'react-iframe';
import { Container, Typography, Grid, Box, Pagination } from '@mui/material';

import { iframeUrls } from '../misc/text/linkedin_posts';

export default function Newsfeed() {
	const [page, setPage] = useState(1);
	const itemsPerPage = 2;

	const handlePageChange = (_, value) => {
		setPage(value);
	};

	const totalItems = iframeUrls.length;

	// range for current page
	const startIndex = (page - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentItems = iframeUrls.slice(startIndex, endIndex);

	return (
		<Container sx={{ paddingBottom: 6 }}>
			<Box paddingTop={6} paddingBottom={2}>
				<Typography variant='h4' fontWeight='bold' mb={2}>
					Follow our Feed
				</Typography>
			</Box>

			<Box sx={{ backgroundColor: 'white', paddingTop: 2, paddingBottom: 2 }}>
				{currentItems.map((url) => {
					const postParts = url.split(':');
					const postId = postParts[postParts.length - 1];
					return (
						<Container key={postId}>
							<Grid container spacing={5} padding={5} justifyContent='center'>
								<Iframe url={url} width='100%' height='500' frameBorder='0' allowFullScreen />
							</Grid>
						</Container>
					);
				})}
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
				<Pagination
					count={Math.ceil(totalItems / itemsPerPage)}
					page={page}
					onChange={handlePageChange}
					color='primary'
				/>
			</Box>
		</Container>
	);
}
